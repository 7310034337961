import React, { useEffect, useCallback, useState, useRef, RefObject } from 'react'
import { RouteComponentProps, useHistory } from 'react-router'
import { createUseStyles, useTheme } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { animated } from 'react-spring'
import { Spring } from 'react-spring/renderprops'
import * as ease from 'd3-ease'
import { Picture, Txt, Carousel } from '../components'
import { ApiService } from '../services'
import { IProduct, IMotionConfig, StoreState, DEFAULT_PRODUCT, ITheme } from '../types'
import { actions as appActions } from '../redux/app'
import { actions as productActions } from '../redux/product'
import { actions as productsActions } from '../redux/products'
import { ReactComponent as NextBtn } from '../assets/icons/next.svg'
import { ReactComponent as PrevBtn } from '../assets/icons/prev.svg'
import { ReactComponent as ScrollIcon } from '../assets/icons/scroll.svg'
import { ReactComponent as InfoIcon } from '../assets/icons/info.svg'
import { CSS } from '../utils/style'

export interface IProps extends IProduct {
  background: string
}

interface IState {
  show: boolean
  isNext?: boolean
}

interface IStyleProps {
  theme: ITheme
  state: IState
}

function getPreloadedItem(products: IProduct[], id: number): IProduct {
  const found = products.filter((it) => it.id === id)
  return found.length ? found[0] : DEFAULT_PRODUCT
}

const Product: React.FC<RouteComponentProps<{ id?: string }>> = ({ match }): JSX.Element | null => {
  const id = parseInt(match.params.id || '')
  const { products } = useSelector(({ products }: StoreState) => ({
    products,
  }))

  const product = products.find((item) => item.id === id) || DEFAULT_PRODUCT

  const preloaded = getPreloadedItem(products, id)
  const [isInstantShow, setIsInstant] = useState(preloaded.background !== '')
  const [state, setState] = useState<IState>({ show: product.id > 0 })

  const { name = '', description = '', technology = '', media = [], background } = product

  const dispatch = useDispatch()
  const theme = useTheme()
  const history = useHistory()
  const css = styles({ theme, state })

  const refMedia = useRef(null)
  const refCarousel = useRef<{ resetScroll: { (): void } }>(null)
  const refScrollIcon = useRef(null)

  const [scrollSeen, setScrollSeen] = useState(false)
  const [showInfo, setShowInfo] = useState(false)

  const isFirstProject = products.length && product.id === products[0].id
  const isLastProject = products.length && product.id === products[products.length - 1].id

  const fetchProduct = useCallback(
    async (productId: number): Promise<void> => {
      if (!productId) return
      // const { data } = await ApiService.getProduct(productId)
      dispatch(appActions.setBackground(''))
      setState({ show: true })
      // dispatch(productActions.setProduct(data))
    },
    [dispatch, setState]
  )

  const fetchProducts = useCallback(async (): Promise<void> => {
    const { data } = await ApiService.getProducts()
    dispatch(productsActions.setProducts(data))
  }, [dispatch])

  useEffect(() => {
    fetchProduct(id)
    if (!products.length) fetchProducts()
    return () => {
      dispatch(productActions.setProduct(DEFAULT_PRODUCT))
    }
  }, [id, fetchProduct, fetchProducts, dispatch, products])

  function changeProject(index: number): void {
    if (index === 0) return history.push('/products')

    const i = products.findIndex((prod) => prod.id === product.id)
    if (i < 0) return

    const newIndex = i + index
    if (newIndex < 0 || newIndex >= products.length) return

    const newState = { show: false, isNext: index > 0 }
    setState(newState)

    const newProduct = products[newIndex]

    setTimeout(() => {
      setState({ ...newState, show: true })
      setIsInstant(true)
      dispatch(productActions.setProduct(DEFAULT_PRODUCT))

      const mediaItems = refCarousel?.current
      if (mediaItems) mediaItems.resetScroll()
      setScrollSeen(false)

      dispatch(productActions.setProduct(newProduct))
      history.push(`/product/${newProduct.id}`)
    }, 600)
  }

  function onMediaScrolled(): void {
    setScrollSeen(true)
  }

  function enableDOM(dom: RefObject<HTMLDivElement>, toEnable: boolean): void {
    if (!dom.current) return
    dom.current.style.pointerEvents = toEnable ? 'initial' : 'none'
  }

  function toggleInfo(): void {
    setShowInfo(!showInfo)
  }

  if (!product.id) return null

  const scrollSeenClass = !scrollSeen && media.length > 1 ? '' : 'seen'

  return (
    <div className={css.page}>
      <div className={css.content}>
        <div className={css.nav}>
          <Spring {...ANIMATION_ARROWS(true, state)}>
            {(motion) => (
              <animated.div
                style={motion}
                className={`${css.navArrowLeft} ${isFirstProject ? 'inactive' : ''}`}
                onClick={() => changeProject(-1)}
              >
                <PrevBtn width={32} height={32} fill="white" />
              </animated.div>
            )}
          </Spring>

          <Spring {...ANIMATION_PROJECTS_TXT()}>
            {(motion) => (
              <animated.div style={motion} className={css.navTxt} onClick={() => changeProject(0)}>
                <Txt.h5 className="txt">All Projects</Txt.h5>
              </animated.div>
            )}
          </Spring>

          <Spring {...ANIMATION_ARROWS(false, state)}>
            {(motion) => (
              <animated.div
                style={motion}
                className={`${css.navArrowRight} ${isLastProject ? 'inactive' : ''}`}
                onClick={() => changeProject(+1)}
              >
                <NextBtn width={32} height={32} fill="white" />
              </animated.div>
            )}
          </Spring>
        </div>
        <div className={`${css.projectContainer} ${showInfo ? 'show' : ''}`}>
          <div className={css.project}>
            <InfoIcon onClick={toggleInfo} className={css.infoIcon} />
            <Spring {...ANIMATION_TXT(0, state)}>
              {(motion) => (
                <animated.div className={css.name} style={motion}>
                  <Txt.h3>{name}</Txt.h3>
                </animated.div>
              )}
            </Spring>

            <Spring {...ANIMATION_TXT(1, state)}>
              {(motion) => (
                <animated.div className={css.description} style={motion}>
                  <Txt.txt>{description}</Txt.txt>
                </animated.div>
              )}
            </Spring>

            <Spring {...ANIMATION_TXT(2, state)}>
              {(motion) => (
                <animated.div className={css.technology} style={motion}>
                  <Txt.bold>{technology}</Txt.bold>
                </animated.div>
              )}
            </Spring>
          </div>
          <ScrollIcon
            className={`${css.scrollIcon} ${scrollSeenClass}`}
            width={24}
            height={24}
            ref={refScrollIcon}
          />
        </div>
        <Spring
          {...ANIMATION_MEDIA(state)}
          onStart={() => enableDOM(refMedia, false)}
          onRest={() => enableDOM(refMedia, true)}
        >
          {(motion) => (
            <animated.div className={css.mediaContainer} style={motion} ref={refMedia}>
              <Carousel values={media} ref={refCarousel} notifyOnScrolled={onMediaScrolled} />
            </animated.div>
          )}
        </Spring>
      </div>
      <Spring {...ANIMATION_BACKGROUND(state)}>
        {(motion) => (
          <animated.div className={css.background} style={motion}>
            <Picture
              value={background}
              style={{
                opacity: motion.opacityBackground || 0,
              }}
              instantShow={isInstantShow}
            />
          </animated.div>
        )}
      </Spring>
    </div>
  )
}
export default Product

const ANIMATION_CFG = {
  easing: ease.easeCubicOut,
  duration: 1200,
  delay: 0,
}

const ANIMATION_ARROWS = (isLeftArrow: boolean, state: IState): IMotionConfig => {
  if (state.isNext !== undefined) return {}

  return {
    from: { opacity: 0, transform: `translateX(${isLeftArrow ? 10 : -10}px)` },
    to: { opacity: 1, transform: `translateX(0)` },
    config: { ...ANIMATION_CFG, delay: 2300, duration: 300 },
  }
}

const ANIMATION_PROJECTS_TXT = (): IMotionConfig => ({
  from: { opacity: 0, marginTop: 20 },
  to: { opacity: 1, marginTop: 0 },
  config: { ...ANIMATION_CFG, delay: 2000, duration: 300 },
})

const ANIMATION_TXT = (index: number, state: IState): IMotionConfig => {
  const move: IMotionConfig = { config: { ...ANIMATION_CFG, delay: 800 + index * 200 } }

  if (state.show) {
    if (state.isNext === undefined) {
      move.from = { opacity: 0, marginTop: 200, marginLeft: '0%' }
      move.to = { opacity: 1, marginTop: 0 }
    } else {
      move.reset = true
      move.from = { opacity: 0, marginTop: 0, marginLeft: state.isNext ? '50%' : '-50%' }
      move.to = { opacity: 1, marginLeft: '0%' }
      move.config = { delay: index * 100, duration: 400, easing: ANIMATION_CFG.easing }
    }
  } else {
    if (state.isNext === undefined) return {}
    move.config = { delay: index * 100, duration: 400, easing: ANIMATION_CFG.easing }
    move.to = { marginLeft: state.isNext ? '-50%' : '50%', opacity: 0 }
  }

  return move
}

const ANIMATION_MEDIA = (state: IState): IMotionConfig => {
  const move: IMotionConfig = {
    config: { easing: ease.easeExpInOut, delay: 0, duration: 800 },
    from: { top: '100%', right: '0%' },
  }

  if (state.show) {
    if (state.isNext !== undefined) {
      move.reset = true
      move.from = { top: '0%', right: state.isNext ? '-100%' : '100%' }
    } else {
      if (move.config) move.config.duration = 1200
    }
    move.to = { top: '0%', right: '0%' }
  } else {
    if (state.isNext !== undefined) move.to = { right: state.isNext ? '100%' : '-100%' }
    if (move.config && !state.isNext) move.config.delay = 0
  }

  // console.log('ANIMATION_MEDIA', move, state)

  return move
}

const ANIMATION_BACKGROUND = (state: IState): IMotionConfig => {
  const move: IMotionConfig = {
    from: { height: '100%', opacityBackground: 1 },
    to: { height: '30%', opacityBackground: state.show ? 0.4 : 0 },
    config: { easing: ease.easeCubicInOut, delay: 500, duration: 800 },
  }

  if (state.isNext !== undefined && move.config) {
    move.config.delay = 0
    move.config.duration = 500
  }

  return move
}

export const styles = createUseStyles({
  page: {
    display: 'flex',
    height: '100%',
    position: 'relative',
    // backgroundColor: (theme: ITheme) => theme.color.projectBackground,
  },
  background: {
    ...CSS.full,
    zIndex: 0,
    backgroundColor: ({ state }: IStyleProps) =>
      state.show || state.isNext !== undefined ? 'black' : '',
    '& > div': {
      height: '100%',
    },
    '@media (max-width: 1024px)': {
      height: '5%!important',
    },
  },
  content: {
    ...CSS.full,
    zIndex: 1,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
  },
  projectContainer: {
    width: '30%',
    height: '70%',
    top: '30%',
    position: 'relative',
    '& >div': {
      marginBottom: 15,
    },
    '@media (max-width: 1024px)': {
      width: '100%',
      height: 'auto',
      zIndex: 3,
      top: 'auto',
      bottom: 70,
      position: 'absolute',
      backgroundColor: 'white',
      transition: 'transform 350ms ease-in-out',
      transform: 'translateY(100%)',

      '&.show': {
        transform: 'translateY(70px)',
      },

      '& $infoIcon': {
        display: 'block',
      },
    },
  },
  infoIcon: {
    position: 'absolute',
    right: 18,
    top: 32,
    display: 'none',
    width: 24,
    height: 24,
  },

  project: {
    padding: 50,
    '@media (max-width: 1024px)': {
      padding: 20,
    },
  },
  name: {
    marginBottom: [20, '!important'],
    width: '100%',
  },
  description: { marginBottom: 20, width: '100%' },
  technology: { width: '100%' },
  mediaContainer: {
    width: '70%',
    height: '100%',
    position: 'absolute',
    paddingRight: 20,
    right: 0,
    '@media (max-width: 1024px)': {
      width: '100%',
      paddingRight: 0,
      height: '95%',
      top: '5%!important',
      '& .carousel': {
        top: '0%!important',
      },
    },
  },
  nav: {
    position: 'absolute',
    width: '30%',
    height: '30%',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    color: 'white',
    '& $navTxt,& $navArrowLeft,& $navArrowRight': {
      transition: 'transform 300ms ease-out,opacity 300ms ease-out',
      cursor: 'pointer',
    },
    '& .inactive': {
      pointerEvents: 'none',
      opacity: [0.0, '!important'],
    },
    '@media (max-width: 1024px)': {
      height: '5%',
      width: '100%',
      zIndex: 3,
    },
  },
  navTxt: {
    textAlign: 'center',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  navArrowLeft: {
    paddingLeft: 50,
    opacity: 0.5,
    '&:hover': {
      // transform: 'translateX(-10px)!important',
      transform: 'scale(1.1)!important',
    },
    '&:active': {
      transform: 'scale(1)!important',
    },
  },
  navArrowRight: {
    paddingRight: 60,
    '&:hover': {
      // transform: 'translateX(10px)!important',
      transform: 'scale(1.1)!important',
    },
    '&:active': {
      transform: 'scale(1)!important',
    },
  },
  '@keyframes slide': {
    '0%': { transform: 'translateY(-15px)', opacity: 1 },
    '100%': { transform: 'translateY(15px)', opacity: 0.5 },
  },
  scrollIcon: {
    position: 'absolute',
    bottom: 10,
    transition: 'transform 1000ms ease-in-out,opacity 1000ms ease-out',
    transitionDelay: '5000ms!important',
    right: 0,
    animation: '$slide 1000ms infinite alternate',
    '&.seen': { opacity: 0, animation: 'initial' },
    '@media (max-width: 1024px)': {
      display: 'none!important',
    },
  },
})
