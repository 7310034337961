export type IActionApp = {
  type: string
  payload: string
}

export enum actionTypes {
  SET_BACKGROUND = 'SET_BACKGROUND',
}

const setBackground = (url: string): IActionApp => ({
  type: actionTypes.SET_BACKGROUND,
  payload: url,
})

export const actions = {
  setBackground,
}

export const INITIAL_STATE = {
  background: '',
}

export const reducer = (
  state = INITIAL_STATE,
  { type, payload }: IActionApp
): typeof INITIAL_STATE => {
  switch (type) {
    case actionTypes.SET_BACKGROUND:
      return { ...state, background: payload }

    default:
      return state
  }
}
