import React, { useState, useEffect, ForwardedRef, useCallback, useRef, CSSProperties } from 'react'
import { createUseStyles } from 'react-jss'
import { IBaseComponentProps } from '../../types'
// import { calculateResize } from '../../utils/sizing'
import { computedWidth, CSS } from '../../utils/style'
import Spinner from '../Spinner'

interface IProps extends IBaseComponentProps {
  value: string
  fit?: boolean
  aspectRatio?: number
  downsize?: boolean
  instantShow?: boolean
  styleContainer?: CSSProperties
  classNameContainer?: string
  onLoaded?: { (e: any): void }
}

export interface IPropsStyle {
  url: string
  computedRatio: number
  instantShow: boolean
  contentWidth: number
  fit: boolean
  aspectRatio?: number
}

const Picture = React.forwardRef((props: IProps, forwardRef: ForwardedRef<HTMLDivElement>) => {
  const {
    value,
    className = '',
    classNameContainer = '',
    style,
    styleContainer,
    aspectRatio,
    downsize = false,
    instantShow = false,
    fit = false,
    onLoaded,
  } = props

  const ref = useRef<HTMLDivElement>(null)

  const [loading, setLoading] = useState(false)
  const [url, setImage] = useState('')
  const [computedRatio, setComputedRatio] = useState(0)
  const [contentWidth, setContentWidth] = useState(0)

  const styleProps: IPropsStyle = {
    url,
    computedRatio,
    instantShow,
    contentWidth,
    fit,
    aspectRatio,
  }
  const css = styles(styleProps)

  const resizeContent = useCallback(
    (img: HTMLImageElement): void => {
      const parent = (ref?.current?.parentNode as Element) ?? null
      if (!parent) return

      downsize && img.width < computedWidth(parent) && setContentWidth(img.width)

      const newRatio = aspectRatio ? aspectRatio : img.width / img.height
      setComputedRatio(newRatio)
    },
    [aspectRatio, downsize, ref]
  )

  useEffect(() => {
    const tmp = new Image()
    tmp.src = value

    if (tmp.width) {
      setImage(value)
      setLoading(false)
      resizeContent(tmp)
      // if (onLoaded) onLoaded({ target: tmp, url: value })
    } else {
      setImage('')
      setLoading(true)
      tmp.onload = () => {
        setImage(value)
        setLoading(false)
        resizeContent(tmp)
        if (onLoaded) onLoaded({ target: tmp, url: value })
      }
    }

    const resizeListener = (): void => resizeContent(tmp)
    window.addEventListener('resize', resizeListener)
    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [value, resizeContent, onLoaded])

  const show = url !== '' ? 'show' : ''

  return (
    <div
      ref={forwardRef}
      className={`${css.container} ${classNameContainer}`}
      style={styleContainer}
    >
      <div className={`${css.content} ${className}`} style={style} ref={ref}>
        <div className={`${css.image} ${show}`}>
          <div className={`${css.ratio} `} />
        </div>
        <Spinner visible={loading} className={css.spinner} />
      </div>
    </div>
  )
})
Picture.displayName = 'Picture'

export const styles = createUseStyles({
  container: {
    minWidth: 20,
    minHeight: 20,
    position: 'relative',
    ...CSS.centerContents,
    display: 'flex',
  },
  content: {
    width: ({ contentWidth }: IPropsStyle) => (contentWidth ? `${contentWidth}px` : '100%'),
    height: ({ aspectRatio }: IPropsStyle) => (aspectRatio === undefined ? '100%' : 'auto'),
    backgroundColor: ({ instantShow }: IPropsStyle) => (instantShow ? 'transparent' : 'black'),
    position: 'relative',
    ...CSS.centerContents,
    display: 'flex',
    overflow: 'hidden',
  },
  image: {
    width: '100%',
    height: ({ aspectRatio }: IPropsStyle) => (aspectRatio === undefined ? '100%' : 'auto'),
    // position: 'relative',
    backgroundSize: ({ fit }: IPropsStyle) => (fit ? 'contain' : 'cover'),
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundImage: ({ url }: IPropsStyle) => `url(${url}`,

    transition: ({ instantShow }: IPropsStyle) =>
      instantShow ? '' : 'opacity 500ms ease-out,transform 500ms ease-out',
    transform: ({ instantShow }: IPropsStyle) => (instantShow ? 'scale(1)' : 'scale(1.2)'),
    opacity: ({ instantShow }: IPropsStyle) => (instantShow ? 1 : 0),
    zIndex: 1,
    '&.show': {
      opacity: 1,
      transform: 'initial',
    },
  },
  ratio: {
    width: 0,
    height: 'auto',
    paddingBottom: ({ computedRatio }: IPropsStyle) => `${(1 / computedRatio) * 100}%`,
    // transition: 'padding-bottom 1000ms ease-out', // performance consideration
  },
  spinner: {
    zIndex: 2,
    position: 'absolute',
  },
})

export default Picture
