import IAction from '../types/IAction'
import IProduct, { DEFAULT_PRODUCT } from '../types/IProduct'
import IMedia from '../types/IMedia'

export type IActionProduct = IAction<IProduct> | IAction<IMedia>

export enum actionTypes {
  SET_PRODUCT = 'SET_PRODUCT',
  ADD_MEDIA = 'ADD_MEDIA',
  CHANGE_MEDIA = 'CHANGE_MEDIA',
  DELETE_MEDIA = 'DELETE_MEDIA',
}

const setProduct = (product: IProduct): IActionProduct => ({
  type: actionTypes.SET_PRODUCT,
  payload: product,
})

const addMedia = (item: IMedia): IActionProduct => ({
  type: actionTypes.ADD_MEDIA,
  payload: item,
})

const changeMedia = (item: IMedia): IActionProduct => ({
  type: actionTypes.CHANGE_MEDIA,
  payload: item,
})

const deleteMedia = (item: IMedia): IActionProduct => ({
  type: actionTypes.DELETE_MEDIA,
  payload: item,
})

export const actions = {
  setProduct,
  addMedia,
  changeMedia,
  deleteMedia,
}

export const INITIAL_STATE = {
  ...DEFAULT_PRODUCT,
}

export const reducer = (state = INITIAL_STATE, { type, payload }: IActionProduct): IProduct => {
  switch (type) {
    case actionTypes.SET_PRODUCT:
      return { ...state, ...payload }

    case actionTypes.ADD_MEDIA: {
      const item = payload as IMedia
      const media = [...state.media]
      item.index = media.length
      media.push(item)
      return { ...state, media }
    }

    case actionTypes.CHANGE_MEDIA: {
      const item = payload as IMedia
      const { index = -1 } = item
      if (!(item && index >= 0)) return state
      const media = [...state.media]
      media[index] = item
      return { ...state, media }
    }

    case actionTypes.DELETE_MEDIA: {
      const item = payload as IMedia
      const { index = -1 } = item
      if (!(item && index >= 0)) return state
      const media = [...state.media]
      media.splice(index, 1)
      return { ...state, media }
    }

    default:
      return state
  }
}
