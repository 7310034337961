import request from '../utils/request'
import IProduct from '../types/IProduct'

const getToken = (): string | null => ''

class ApiService {
  getProducts = async (): Promise<{ data: IProduct[] }> => {
    return request.get(`/projects`, { headers: { Authorization: getToken() } })
  }

  getProduct = async (id: string | number): Promise<{ data: IProduct }> => {
    return request.get(`/project/${id}`, { headers: { Authorization: getToken() } })
  }
}

export default new ApiService()
