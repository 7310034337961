import IAction from '../types/IAction'
import IProduct from '../types/IProduct'

export type IActionProducts = IAction<IProduct[]>

export enum actionTypes {
  SET_PRODUCTS = 'SET_PRODUCTS',
}

const setProducts = (product: IProduct[]): IActionProducts => ({
  type: actionTypes.SET_PRODUCTS,
  payload: product,
})

export const actions = {
  setProducts,
}

export const INITIAL_STATE: IProduct[] = []

export const reducer = (state = INITIAL_STATE, { type, payload }: IActionProducts): IProduct[] => {
  switch (type) {
    case actionTypes.SET_PRODUCTS:
      return payload ? payload : []

    default:
      return state
  }
}
