import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import { reducer as app } from './app'
import { reducer as product } from './product'
import { reducer as products } from './products'

const reducers = combineReducers({
  app,
  product,
  products,
})

export type StoreState = ReturnType<typeof reducers>

const composeEnhancers = composeWithDevTools({
  // options
})
const enhancers = composeEnhancers(applyMiddleware(thunk))

const store = createStore(reducers, enhancers)
export default store
