export const firstClass = (classNames = ''): string => classNames.split(' ')[0]

/**
 * Defines css easings based on regular animation functions
 * Source: https://easings.net
 */
export const EASE = {
  out: {
    cubic: 'cubic-bezier(0.33, 1, 0.68, 1)',
  },
  inOut: {
    cubic: 'cubic-bezier(0.65, 0, 0.35, 1)',
  },
}

export const CSS = {
  centerContents: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  full: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
}

export const computedProperty = (dom: Element, propertyName: string): number => {
  return parseFloat(window.getComputedStyle(dom).getPropertyValue(propertyName))
}

export const computedWidth = (dom: Element): number => {
  return computedProperty(dom, 'width')
}

export const computedHeight = (dom: Element): number => {
  return computedProperty(dom, 'height')
}

export const computedSize = (dom: Element): { width: number; height: number } => ({
  width: computedWidth(dom),
  height: computedHeight(dom),
})

export const computedPropertyRaw = (dom: Element, propertyName: string): string | number => {
  return window.getComputedStyle(dom).getPropertyValue(propertyName)
}
