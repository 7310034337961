import React, { createRef } from 'react'
import withStyles, { Styles, WithStylesProps } from 'react-jss'
import { IMedia, ITheme } from '../../../types'
import MediaItem from './Item'

import { computedProperty, computedHeight } from '../../../utils/style'

interface IProps extends WithStylesProps<typeof style> {
  className?: string
  values: IMedia[]
  ref?: React.Ref<{ resetScroll: { (): void } }>
  notifyOnScrolled?: { (): void }
}

interface IState {
  scrollPos: number
  maxScrollPos: number
  scrollNotified: boolean
}

class Carousel extends React.PureComponent<IProps, IState> {
  getSelectedIndex(): number {
    const { scrollPos, maxScrollPos } = this.state

    if (!this.ref.current) return 0

    const list = this.ref.current.children

    const koef = scrollPos / maxScrollPos
    const heightList = computedHeight(this.ref.current)
    const realPos = koef * heightList

    let edge = heightList
    let index = 0
    for (let i = list.length - 1; i >= 0; i--) {
      const item = list[i]
      const height = computedHeight(item)
      edge -= height + 1
      if (realPos >= edge) {
        index = i
        break
      }
    }

    return index
  }

  getItems(): JSX.Element[] {
    const { values } = this.props

    const index = this.getSelectedIndex()

    const items: JSX.Element[] = values.map((item, i) => {
      const active = index === i ? 'active' : 'inactive'
      return (
        <div key={`${item.id}_${item.index}`} className={`item ${active}`}>
          <MediaItem
            value={item}
            style={ITEM_SPECIFIC_STYLE}
            aspectRatio={0}
            downsize={true}
            onLoaded={() => this.onItemLoaded()}
          />
        </div>
      )
    })

    return items
  }

  protected onItemLoaded(): void {
    this.onResize()
  }

  private ref
  private onScrollRef: { (e: WheelEvent): void }
  private onResizeRef: { (): void }
  private onTouchMoveRef: { (e: TouchEvent): void }

  protected touchStartY = 0

  constructor(props: IProps) {
    super(props)

    this.state = {
      scrollPos: 0,
      maxScrollPos: 0,
      scrollNotified: false,
    }

    this.ref = createRef<HTMLDivElement>()
    this.onScrollRef = (e) => this.onScroll(e)
    this.onResizeRef = () => this.onResize()
    this.onTouchMoveRef = (e) => this.onTouchMove(e)
  }

  limitScrollPos(scrollPos: number, maxScrollPos: number): number {
    if (scrollPos < 0) return 0
    if (scrollPos > maxScrollPos) return maxScrollPos
    return scrollPos
  }

  onTouchMove(e: TouchEvent): void {
    const touchobj = e.changedTouches[0]
    if (e.type === 'touchstart') {
      this.touchStartY = touchobj.pageY
    } else if (e.type === 'touchend') {
      // nothing
    } else {
      const deltaY = touchobj.pageY - this.touchStartY
      this.moveScrollPos(-deltaY)
      this.touchStartY = touchobj.pageY
    }
  }

  onScroll(event: WheelEvent): void {
    const { deltaY } = event
    this.moveScrollPos(deltaY)
  }

  moveScrollPos(deltaY: number): void {
    const { scrollPos, maxScrollPos, scrollNotified } = this.state
    const newScrollPos = this.limitScrollPos(scrollPos + deltaY, maxScrollPos)
    if (!scrollNotified && scrollPos > 300) {
      if (this.props.notifyOnScrolled) this.props.notifyOnScrolled()
      this.setState({ scrollPos: newScrollPos, scrollNotified: true })
    } else {
      this.setState({ scrollPos: newScrollPos })
    }
  }

  onResize(): void {
    const scrollView = this.ref.current
    if (!scrollView) return

    const { clientHeight = 0 } = scrollView

    const { scrollPos } = this.state

    const parent = scrollView.parentNode as HTMLDivElement

    let parentHeight = computedHeight(parent)
    const scrollViewOffset = computedProperty(scrollView, 'top')
    parentHeight -= scrollViewOffset

    const maxScrollPos = clientHeight - parentHeight + SCROLL_BOTTOM_MARGIN
    const newScrollPos = this.limitScrollPos(scrollPos, maxScrollPos)

    this.setState({ maxScrollPos, scrollPos: newScrollPos })
  }

  componentDidMount(): void {
    document.addEventListener('wheel', this.onScrollRef)
    window.addEventListener('resize', this.onResizeRef)
    window.addEventListener('touchstart', this.onTouchMoveRef)
    window.addEventListener('touchmove', this.onTouchMoveRef)
    window.addEventListener('touchend', this.onTouchMoveRef)
    setTimeout(() => this.onResize(), 500)
  }

  componentWillUnmount(): void {
    document.removeEventListener('wheel', this.onScrollRef)
    window.removeEventListener('resize', this.onResizeRef)
    window.removeEventListener('touchstart', this.onTouchMoveRef)
    window.removeEventListener('touchmove', this.onTouchMoveRef)
    window.removeEventListener('touchend', this.onTouchMoveRef)
  }

  render(): React.ReactNode {
    const { classes: css } = this.props
    const { scrollPos } = this.state

    const items = this.getItems()

    return (
      <div className={`${css.container} carousel`}>
        <div className={css.scrollList} style={{ marginTop: -scrollPos }} ref={this.ref}>
          {items}
        </div>
      </div>
    )
  }

  resetScroll(): void {
    this.setState({ scrollPos: 0, maxScrollPos: 0, scrollNotified: false })
    setTimeout(this.onResizeRef, 100)
  }
}

const SCROLL_BOTTOM_MARGIN = 200
const ITEM_SPECIFIC_STYLE = {
  boxShadow: 'rgba(0,0,0,0.35) -5px 5px 5px',
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const style = (theme: ITheme): Styles => {
  return {
    container: {
      overflow: 'hidden',
      width: '100%',
      height: '100%',
      position: 'relative',
      marginLeft: -10,
      paddingLeft: 20,
    },
    scrollList: {
      width: '100%',
      display: 'inline-block',
      position: 'relative',
      top: '20%',
      // paddingRight: 10,
      '& .inactive,& .active': {
        transition: 'transform 500ms ease-out,opacity 500ms ease-out',
      },

      '& .inactive': {
        // transform: 'perspective(4000px) scale(0.95) rotateY(-2deg) rotateZ(-2deg) translateX(0px)',
        transform: 'scale(0.95) translateX(0)',
      },
      '& .active': {
        // transform: 'scale(1) rotateY(0deg) rotateZ(0deg) translateX(-20px)',
        transform: 'scale(1) translateX(-10px)',
      },

      '& .inactive .show': {
        opacity: 0.1,
      },
      '& .active .show': {
        opacity: 1,
      },
    },
  }
}

export default withStyles(style, { injectTheme: true })(Carousel)
