import React from 'react'
import { useTheme } from 'react-jss'
import Theme from '../../types/ITheme'

interface IPropsTxt {
  className?: string
}

const H1: React.FC<IPropsTxt> = (props) => {
  const theme: Theme = useTheme()
  return <span style={theme.fonts.h1} {...props} />
}

const H2: React.FC<IPropsTxt> = (props) => {
  const theme: Theme = useTheme()
  return <span style={theme.fonts.h2} {...props} />
}

const H3: React.FC<IPropsTxt> = (props) => {
  const theme: Theme = useTheme()
  return <span style={theme.fonts.h3} {...props} />
}

const H4: React.FC<IPropsTxt> = (props) => {
  const theme: Theme = useTheme()
  return <span style={theme.fonts.h4} {...props} />
}

const H5: React.FC<IPropsTxt> = (props) => {
  const theme: Theme = useTheme()
  return <span style={theme.fonts.h5} {...props} />
}

const Txt: React.FC<IPropsTxt> = (props) => {
  const theme: Theme = useTheme()
  return <span style={theme.fonts.txt} {...props} />
}

const Bold: React.FC<IPropsTxt> = (props) => {
  const theme: Theme = useTheme()
  return <span style={theme.fonts.bold} {...props} />
}

const Link: React.FC<IPropsTxt> = (props) => {
  const theme: Theme = useTheme()
  return <span style={theme.fonts.link} {...props} />
}

export default { h1: H1, h2: H2, h3: H3, h4: H4, h5: H5, txt: Txt, bold: Bold, link: Link }
