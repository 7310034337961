import React, { CSSProperties, ForwardedRef } from 'react'
import { createUseStyles } from 'react-jss'
import { animated } from 'react-spring'
import { Txt } from '..'
import { IProduct } from '../../types'
import { CSS } from '../../utils/style'
import Picture from '../Media/Picture'

interface IProps {
  value: IProduct
  motion?: CSSProperties
  onClick?: { (): void }
  className?: string
  style?: CSSProperties
  ref: React.RefObject<HTMLDivElement>
}

const ProjectCard = React.forwardRef((props: IProps, forwardRef: ForwardedRef<HTMLDivElement>) => {
  const { value: product, className, motion, style, onClick } = props
  const { name, technology, background } = product

  const css = styles(props)

  return (
    <animated.div
      className={`${css.container} ${className}`}
      ref={forwardRef}
      style={{ ...style, ...motion }}
      onClick={onClick}
    >
      <div className={css.infoContainer}>
        <div className={css.name}>
          <Txt.h5>{name}</Txt.h5>
        </div>
        <div className={css.technology}>
          <Txt.bold>{technology}</Txt.bold>
        </div>
      </div>
      <Picture value={background} aspectRatio={undefined} />
    </animated.div>
  )
})

ProjectCard.displayName = 'ProjectCard'

const styles = createUseStyles({
  container: {
    '&:hover $infoContainer': {
      opacity: 1,
    },
    '&.clicked $infoContainer': {
      opacity: 0,
    },
    cursor: 'pointer',
    // minWidth: 200,
    // minHeight: 200,
    position: 'relative',
  },
  infoContainer: {
    transition: 'opacity 300ms ease-out',
    opacity: 0,
    ...CSS.full,
    ...CSS.centerContents,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    zIndex: 2,
    backgroundColor: 'rgba(0,0,0,0.85)',
    color: 'white',
    textAlign: 'center',
  },
  name: {},
  technology: {
    marginTop: 10,
    color: 'yellow',
    padding: '0 20px',
  },
})

export default ProjectCard
