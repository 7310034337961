import IMedia from './IMedia'

export interface IProduct {
  id: number
  active: boolean
  index: number
  name: string
  description: string
  technology: string
  media: IMedia[]
  background: string
}

export const DEFAULT_PRODUCT: IProduct = {
  id: 0,
  active: true,
  index: 0,
  name: '',
  description: '',
  technology: '',
  media: [],
  background: '',
}

export default IProduct
