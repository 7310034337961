import React from 'react'
import { createUseStyles } from 'react-jss'
import { IMedia, IStyle } from '../../../types'
import Picture from '../Picture'
import Video from '../Video'

interface IProps {
  value: IMedia
  fit?: boolean
  className?: string
  in?: boolean
  aspectRatio?: number
  style?: IStyle
  animationClass?: string
  downsize?: boolean
  onLoaded?: { (e: any): void }
}

const MediaItem = React.forwardRef(
  (props: IProps, forwardedRef: React.ForwardedRef<HTMLDivElement>) => {
    const { value, className, fit, aspectRatio = 16 / 9, downsize = false, style, onLoaded } = props

    const { type, url } = value

    // const ref = useRef(null)

    const MediaComponent = type === 'mp4' ? Video : Picture

    return (
      <MediaComponent
        value={url}
        className={className}
        aspectRatio={aspectRatio}
        fit={fit}
        ref={forwardedRef}
        style={style}
        downsize={downsize}
        onLoaded={onLoaded}
      />
    )
  }
)

MediaItem.displayName = 'MediaItem'

export default MediaItem

export const style = createUseStyles({})
