import React from 'react'
import { ThemeProvider } from 'react-jss'
import { Provider as ApplicationState, useSelector } from 'react-redux'
import Router from './config/routes'
import theme from './config/theme'
import store, { StoreState } from './redux'
import { Picture } from './components'

import './App.css'
import { CSS } from './utils/style'

function App(): JSX.Element {
  return (
    <ApplicationState store={store}>
      <ThemeProvider theme={theme}>
        <Router />
        <AppBackground />
      </ThemeProvider>
    </ApplicationState>
  )
}

function AppBackground(): JSX.Element | null {
  const background = useSelector(({ app }: StoreState) => app.background)
  return background === '' ? null : <Picture value={background} instantShow={true} style={cover} />
}

const cover = { zIndex: -1, ...CSS.full, left: 0, top: 0 }
export default App
