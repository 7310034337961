import React, { useRef } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { IBaseComponentProps } from '../../types/IComponentProps'
import { CSSTransition } from 'react-transition-group'
import { ITheme } from '../../types'
import { firstClass } from '../../utils/style'

interface IProps extends IBaseComponentProps {
  visible?: boolean
}

const Spinner: React.FC<IProps> = (props) => {
  const { visible, className = '' } = props
  const ref = useRef(null)
  const theme: ITheme = useTheme()
  const css = styles(theme)

  return (
    <CSSTransition
      in={visible}
      nodeRef={ref}
      timeout={theme.speed.transitions}
      classNames={firstClass(css.container)}
      unmountOnExit={true}
      mountOnEnter={true}
    >
      <div className={`${css.container} ${className}`} ref={ref}>
        <div className={`${css.dot} ${css.delay1}`} />
        <div className={`${css.dot} ${css.delay2}`} />
        <div className={`${css.dot} ${css.delay3}`} />
      </div>
    </CSSTransition>
  )
}
export default Spinner

const styles = createUseStyles({
  container: {
    transition: (theme: ITheme) => `opacity ${theme.speed.transitions}ms ease-in-out`,
    opacity: 0,
    '&-enter-active , &-enter-done': {
      opacity: 1,
    },
  },
  dot: {
    width: '15px',
    height: '15px',
    backgroundColor: '#333',
    borderRadius: '100%',
    display: 'inline-block',
    animation: '$bounce 1.5s infinite ease-in-out both',
  },
  delay1: { 'animation-delay': '0.1s' },
  delay2: { 'animation-delay': '0.3s' },
  delay3: { 'animation-delay': '0.5s' },
  '@keyframes bounce': {
    '0%, 80%, 100%': {
      transform: 'scale(0.5)',
      opacity: 0.3,
    },
    '40%': {
      transform: 'scale(1.0)',
      opacity: 0.7,
    },
  },
})
