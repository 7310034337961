export interface IBox {
  width: number
  height: number
}

export const calculateResize = (
  max: IBox,
  size: IBox,
  fit: boolean
): IBox & { x: number; y: number } => {
  let width: number
  let height: number

  const ratioMax = max.width / max.height
  const ratio = size.width / size.height

  if ((fit && ratio > ratioMax) || (!fit && ratioMax > ratio)) {
    width = max.width
    height = width / ratio
  } else {
    height = max.height
    width = height * ratio
  }

  const x = (max.width - width) / 2
  const y = (max.height - height) / 2

  return {
    width,
    height,
    x,
    y,
  }
}
