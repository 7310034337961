const fontBig = 'Abel'
const fontNormal = 'Montserrat'

export default {
  fonts: {
    h1: {
      fontSize: 48,
      fontFamily: fontBig,
      fontWeight: 400,
    },
    h2: {
      fontSize: 40,
      fontFamily: fontBig,
      fontWeight: 400,
    },
    h3: {
      fontSize: 32,
      fontFamily: fontBig,
      fontWeight: 400,
    },
    h4: {
      fontSize: 24,
      fontFamily: fontBig,
      fontWeight: 400,
    },
    h5: {
      fontSize: 20,
      fontFamily: fontBig,
      fontWeight: 400,
    },
    txt: {
      fontSize: 16,
      fontFamily: fontNormal,
      fontWeight: 400,
    },
    bold: {
      fontSize: 16,
      fontFamily: fontNormal,
      fontWeight: 600,
    },
    link: {
      fontSize: 16,
      fontFamily: fontNormal,
      fontWeight: 400,
    },
  },
  color: {
    background: '#e3e3e3',
    projectBackground: '#e3e3e3',
    primary: 'cyan',
  },
  speed: {
    transitions: 500,
  },
}
