import React, { useEffect, useCallback, useRef, useState } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { ProjectCard } from '../components'
import { ApiService } from '../services'
import { IProduct, StoreState, IMotionConfig } from '../types'
import * as ease from 'd3-ease'
import { actions as appActions } from '../redux/app'

import { useSprings } from 'react-spring'
import { Spring } from 'react-spring/renderprops'

import { actions as productsActions } from '../redux/products'
import { useHistory } from 'react-router-dom'
import { computedSize } from '../utils/style'

interface IProps {
  values: IProduct[]
}

interface IAnimatedPos {
  left: number | string
  top: number | string
  width?: string | number
  height?: string | number
}

interface IClickedElement {
  item: IProduct
  from: IAnimatedPos
  to: IAnimatedPos
  index: number
}

const ANIMATION_INTRO = (): IMotionConfig => {
  return {
    opacity: 1,
    transform: 'scale(1)',
    delay: Math.random() * 500,
    from: { opacity: 0, transform: 'scale(0.3)', width: '33.3%', height: '33.3%' },
  }
}

const ANIMATION_SELECTED = (index: number) => (i: number): IMotionConfig => {
  if (index === i) {
    return {
      to: { transform: 'scale(1.1)' },
      config: { easing: ease.easeCircleOut, duration: 300 },
    }
  } else {
    return {
      to: { opacity: 0, transform: 'scale(0)' },
      delay: Math.random() * 500,
    }
  }
}

export const Projects: React.FC<IProps> = (props) => {
  const { values: projects } = props
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()
  const css = styles(theme)

  const [selected, setSelected] = useState<IClickedElement>()

  const itemsRef = useRef<Array<HTMLDivElement | null>>([])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [springs, setAnimation] = useSprings<any>(projects.length, ANIMATION_INTRO)

  if (!projects) return null

  function showItem(id: number, index = -1): void {
    const item = projects[index]

    const ref = itemsRef.current[index] as HTMLDivElement
    if (!ref) return
    ref.className += ' clicked'
    ref.style.zIndex = '1'

    let { width, height } = computedSize(ref)

    width = (width / window.innerWidth) * 100
    height = (height / window.innerHeight) * 100

    const from: IAnimatedPos = {
      left: ref?.offsetLeft,
      top: ref?.offsetTop,
      width: width + '%',
      height: height + '%',
    }

    const to: IAnimatedPos = { left: 0, top: 0, width: '100%', height: '100%' }
    setAnimation(ANIMATION_SELECTED(index))
    setTimeout(() => {
      setSelected({ item, from, to, index })
    }, 500)
  }

  function switchSection(): void {
    if (!selected?.item.id) return
    dispatch(appActions.setBackground(selected.item.background))
    setTimeout(() => {
      history.push(`/product/${selected?.item.id}`)
    }, 100)
  }

  return (
    <div className={css.page}>
      <div className={css.projectsContainer}>
        {springs.map((motion, i) => {
          const { id, index } = projects[i]
          return (
            <ProjectCard
              key={`${id}_${index}`}
              motion={motion}
              value={projects[i]}
              onClick={() => showItem(id, i)}
              className={css.project}
              style={{ opacity: 1 }}
              ref={(e) => (itemsRef.current[i] = e)}
            />
          )
        })}
        {selected && (
          <Spring
            from={{ ...selected.from, opacity: 1, transform: 'scale(1.1)' }}
            to={{ ...selected.to, opacity: 1, transform: 'scale(1)' }}
            delay={600}
            config={{ duration: 350, easing: ease.easeCubicInOut }}
            onRest={switchSection}
          >
            {(motion) => (
              <ProjectCard
                value={selected.item}
                motion={motion}
                className={css.project + ' clicked'}
                style={{ position: 'absolute', zIndex: 2 }}
              />
            )}
          </Spring>
        )}
      </div>
    </div>
  )
}

export const ProjectsController: React.FC = () => {
  const products = useSelector(({ products }: StoreState) => products)
  const dispatch = useDispatch()

  const fetchData = useCallback(async (): Promise<void> => {
    const { data } = await ApiService.getProducts()
    dispatch(productsActions.setProducts(data))
  }, [dispatch])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return <Projects values={products} />
}

export const styles = createUseStyles({
  page: {
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
    overflow: 'hidden',
  },
  projectsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    height: '100%',
  },
  project: {
    // transition: 'transform 500ms ease-out,opacity 500ms ease-out',
    // transform: 'perspective(1500px)',
    width: '100px',
    height: '100px',
    opacity: 0,
    overflow: 'hidden',
    '& >div': {
      width: '100%',
      height: '100%',
    },
  },
})

export default ProjectsController
