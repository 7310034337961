import React, { useState, useEffect, useRef, ForwardedRef, useCallback, CSSProperties } from 'react'
import { createUseStyles } from 'react-jss'
import VideoPlayer from 'react-player/file'
import { IBaseComponentProps } from '../../types'
import { calculateResize } from '../../utils/sizing'
import { ReactComponent as Play } from '../../assets/icons/play.svg'

import Spinner from '../Spinner'
import { computedSize, computedWidth, CSS, EASE } from '../../utils/style'
import { IPropsStyle as IPropsStylePicture } from './Picture'

interface IProps extends IBaseComponentProps {
  value: string
  fit?: boolean
  aspectRatio?: number
  downsize?: boolean
  instantShow?: boolean
  styleContainer?: CSSProperties
  classNameContainer?: string
}

interface IState {
  playing: boolean
  progress: number
  duration: number
}

interface IPropsStyle extends IPropsStylePicture {
  layout: {
    width: number
    height: number
    marginLeft: number
    marginTop: number
  }
}

const Video = React.forwardRef((props: IProps, forwardRef: ForwardedRef<HTMLDivElement>) => {
  const {
    value,
    style,
    styleContainer,
    className = '',
    classNameContainer = '',
    downsize = false,
    instantShow = false,
    fit = false,
    aspectRatio,
  } = props

  const ref = useRef<HTMLDivElement>(null)
  const refVideo = useRef<typeof VideoPlayer>(null)

  const [state, setState] = useState<IState>({ playing: false, progress: 0, duration: 0 })

  const [loading, setLoading] = useState(false)
  const [layout, setLayout] = useState({ width: -1, height: 0, marginLeft: 0, marginTop: 0 })
  const [computedRatio, setComputedRatio] = useState(0)
  const [contentWidth, setContentWidth] = useState(0)

  const styleProps: IPropsStyle = { layout, computedRatio, contentWidth, instantShow, fit, url: '' }
  const css = styles(styleProps)

  useEffect(() => {
    setLoading(true)
  }, [value])

  const resizeContent = useCallback((): void => {
    const parent = (ref?.current?.parentNode as Element) ?? null
    if (!(parent && refVideo.current)) return

    const videoPlayer = refVideo.current.getInternalPlayer()
    const { videoWidth, videoHeight } = videoPlayer

    downsize && videoWidth < computedWidth(parent) && setContentWidth(videoWidth)

    const newRatio = aspectRatio ? aspectRatio : videoWidth / videoHeight
    setComputedRatio(newRatio)

    const sizeParent = computedSize(parent)
    const sizeVideo = { width: videoWidth, height: videoHeight }

    const { width, height, x: marginLeft, y: marginTop } = calculateResize(
      sizeParent,
      sizeVideo,
      fit
    )
    const newLayout = { width, height, marginLeft, marginTop }
    setLayout(newLayout)
  }, [aspectRatio, downsize, ref, fit])

  useEffect(() => {
    const resizeListener = (): void => resizeContent()
    window.addEventListener('resize', resizeListener)
    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [resizeContent])

  function play(): void {
    if (!layout.width) return

    setState({ ...state, playing: !state.playing })
  }

  function seek(newProgress: number): void {
    if (!refVideo.current) return
    refVideo.current.seekTo(newProgress * state.duration)
  }

  function onPreloaded(): void {
    setLoading(false)
    resizeContent()
  }

  const showPlayer = loading ? '' : 'show'

  return (
    <div
      ref={forwardRef}
      className={`${css.container} ${classNameContainer}`}
      style={styleContainer}
    >
      <div className={`${css.content} ${className}`} style={style} ref={ref}>
        <div className={css.ratio} />
        <VideoPlayer
          className={`${css.player} ${showPlayer}`}
          onReady={onPreloaded}
          url={value}
          playsinline={true}
          style={layout}
          width={layout.width}
          height={layout.height}
          playing={state.playing}
          muted={true}
          onProgress={({ played: progress }: { played: number }) =>
            setState({ ...state, progress })
          }
          onDuration={(duration: number) => setState({ ...state, duration })}
          onEnded={() => setState({ ...state, playing: false })}
          progressInterval={200}
          ref={refVideo}
        />
      </div>
      <div className={css.controlsOverlay}>
        <VideoControls
          playing={state.playing}
          visible={state.playing}
          progress={state.progress}
          onSeek={seek}
          onPlayPause={play}
        />
      </div>
      <Spinner visible={loading} className={css.spinner} />
    </div>
  )
})
Video.displayName = 'Video'

export const styles = createUseStyles({
  container: {
    minWidth: 100,
    minHeight: 100,
    position: 'relative',
    ...CSS.centerContents,
    display: 'flex',
  },

  content: {
    width: ({ contentWidth }: IPropsStyle) => (contentWidth ? `${contentWidth}px` : '100%'),
    height: ({ aspectRatio }: IPropsStyle) => (aspectRatio === undefined ? '100%' : 'auto'),
    backgroundColor: ({ instantShow }: IPropsStyle) => (instantShow ? 'transparent' : 'black'),
    position: 'relative',
    ...CSS.centerContents,
    display: 'flex',
    overflow: 'hidden',
    pointerEvents: ({ layout }: IPropsStyle) => (layout.width ? 'auto' : 'none'),
  },
  player: {
    ...CSS.full,
    transition: ({ instantShow }: IPropsStyle) =>
      instantShow ? '' : 'opacity 500ms ease-out,transform 500ms ease-out',
    transform: ({ instantShow }: IPropsStyle) => (instantShow ? 'scale(1)' : 'scale(1.2)'),
    opacity: ({ instantShow }: IPropsStyle) => (instantShow ? 1 : 0),
    zIndex: 1,
    '&.show': {
      opacity: 1,
      transform: 'initial',
    },
  },
  controlsOverlay: {
    width: ({ contentWidth }: IPropsStyle) => (contentWidth ? `${contentWidth}px` : '100%'),
    height: '100%',
    position: 'absolute',
    zIndex: 4,
  },
  spinner: {
    zIndex: 1,
    position: 'absolute',
  },
  ratio: {
    width: 0,
    height: 'auto',
    paddingBottom: ({ computedRatio }: IPropsStyle) => `${(1 / computedRatio) * 100}%`,
  },
})

export default Video

interface IPropsControls {
  playing: boolean
  progress: number
  visible: boolean
  onSeek?: { (newProgress: number): void }
  className?: string
  onPlayPause?: { (isPlay: boolean): void }
}

export const VideoControls: React.FC<IPropsControls> = (props) => {
  const { className, onSeek, playing, onPlayPause } = props
  const css = stylesControls(props)

  function seek(ev: React.MouseEvent): void {
    const tgt = ev.target as HTMLDivElement

    const newProgress = (ev.clientX - tgt.getBoundingClientRect().left) / computedWidth(tgt)
    if (onSeek) onSeek(newProgress)
    // console.log(ev, progress, computedWidth(tgt), tgt)
  }

  function playPause(): void {
    if (onPlayPause) onPlayPause(!playing)
  }

  return (
    <div className={`${css.controlsContainer}`}>
      <div onClick={playPause} className={css.controlsCover}>
        {!playing && <Play color="white" width="64" height="64" />}
      </div>
      <div className={`${css.controlsBottomContainer}`}>
        <div className={`${css.controlsBottom} ${className}`}>
          <div className="play container">
            <Play color="white" width={24} height={24} />
          </div>
          <div className="progress container" onClick={seek}>
            <div className={css.progressBar} />
          </div>
        </div>
      </div>
    </div>
  )
}

export const stylesControls = createUseStyles({
  controlsContainer: {
    display: 'flex',
    height: '100%',
    ...CSS.centerContents,
  },

  controlsCover: {
    ...CSS.full,
    cursor: 'pointer',
  },

  controlsBottomContainer: {
    width: '100%',
    overflow: 'hidden',
    position: 'absolute',
    bottom: -20,
    height: 20,
  },
  controlsBottom: {
    display: 'flex',
    ...CSS.centerContents,
    backgroundColor: 'rgba(0,0,0,0.4)',

    transition: `transform 300ms ${EASE.out.cubic}`,
    transform: ({ visible }: IPropsControls) => `translateY(${visible ? 0 : -100}%)`,

    '& .play.container': {
      // display: 'inline-flex',
      display: 'none',
    },
    '& .progress.container': {
      display: 'flex',
      flexGrow: 1,
      // backgroundColor: 'black',
      height: 1,
      margin: '0 5px',
      padding: '5px 0',
      position: 'relative',
      cursor: 'pointer',
    },
  },

  progressBar: {
    // transition: 'width 100ms ease-out',
    width: ({ progress }: IPropsControls) => `${progress * 100}%`,
    height: 1,
    backgroundColor: 'white',
    pointerEvents: 'none',
  },
})
