import React, { useCallback, useEffect } from 'react'
import { BrowserRouter, Switch, Route, useHistory } from 'react-router-dom'

// import index from '../pages/index'
import products from '../pages/products'
import product from '../pages/product'
// import Error404 from '../pages/Error404'

function Router(): JSX.Element {
  return (
    <BrowserRouter>
      <LocationListener />
      <Switch>
        <Route exact path="/" component={products} />
        <Route exact path="/products" component={products} />
        <Route path="/product/:id" component={product} />
        <Route exact path="*" component={products} />
      </Switch>
    </BrowserRouter>
  )
}

const LocationListener = (): null => {
  const history = useHistory()

  const onPathChange = useCallback(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
  }, [])

  useEffect(() => {
    const unlisten = history.listen(onPathChange)
    return () => {
      unlisten()
    }
  }, [onPathChange, history])

  return null
}

export default Router
